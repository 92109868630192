/* eslint-disable no-dupe-keys */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as Shivam,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDataFetch from '../../hook/useDataFetch';
import { IClientsTable, IPortalProfileTable, IPortalTable } from '../../interfaces/Itable';
import LoginContext from '../../store/loginContext';
import api from '../../utils/api';
import LoadingSpinner from '../UI/loadingSpinner/LoadingSpinner';
import axios from 'axios';
import { FaFileUpload, FaTimes } from "react-icons/fa";
import { Select } from 'chakra-react-select';



interface FormData {
  // clientName: string;
  client: string;
  proposalType: string;
  currency: string;
  estimatedHours?: string;
  cost?: number;
  grossCost: number;
  projectDeadline: string;
  proposalDesc: string;
  status: string;

}

const HiredProposal = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(LoginContext);
  const { id } = useParams();

  const { pathname } = useLocation();
  const PortalUrl = `${process.env.REACT_APP_API_URL}/api/portal`
  const PortalProfileUrl = `${process.env.REACT_APP_API_URL}/api/portal/profile`
  const ClientUrl = `${process.env.REACT_APP_API_URL}/api/portal/clients`

  const [loadingid, setLoadingID] = useState(false)
  const { data } = useDataFetch<IPortalTable[]>(PortalUrl);
  const { data: clientData, refetch } = useDataFetch<IClientsTable[]>(ClientUrl);
const toast=useToast();


  const [portalName, setportalName] = useState({})

  const [selected2, setSelected2] = useState<{
    value: string;
    label: string;
  }>({
    value: "",
    label: ""
  });

  const [formData, setFormData] = useState<FormData>({
    // clientName: '',
    client: '',
    proposalType: '',
    currency: '',
    estimatedHours: undefined,
    cost: undefined,
    grossCost: 0,
    projectDeadline: new Date().toISOString().split('T')[0],
    proposalDesc: '',
    status: 'hired',
  });


  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [rates, setRates] = useState(0);
  const [price, setPrice] = useState<number | null>(null);

  const apiKey = '98a265629f6f37c3eaf2e783';

  const [isOpen, setIsOpen] = useState(false);
  const [clientName, setClientName] = useState('');

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCreateClient = async () => {


    try {
      const res = await api.post('/portal/create_client', { clientName, creator: user?._id });
      if (res.data) {
        console.log('Client created successfully:', res.data);
        refetch()
        handleCloseModal();
      }
    } catch (err) {
      console.error('Error creating client:', err);
    }

  };

  const handleChangeClientName = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setClientName(event.target.value);
  };




  useEffect(() => {
    async function getData() {
      try {
        setLoadingID(true)
        const res = await api.get('/portal/proposal/' + id);

        setportalName(res?.data?.portal?.portalName)
        setLoadingID(false)
        if (!res.data) {
          return
        }
        const {
          client,
          proposalType,
          currency,
          estimatedHours,
          cost,
          grossCost,
          projectDeadline,
          proposalDesc,
          status,
        } = res.data;
        setSelected2({
          value: client?._id,
          label: client?.clientName
        })
        setFormData({
          // clientName: client?.clientName || '',
          client: client?._id || '',
          proposalType,
          currency,
          estimatedHours: estimatedHours || undefined,
          cost: cost || undefined,
          grossCost,
          projectDeadline: projectDeadline ? new Date(projectDeadline).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
          proposalDesc,
          status: 'hired',
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (id) getData();
  }, [id]);


  useEffect(() => {
    if (data) {

      const portal = data.find(item => item.portalName === portalName);
      if (portal) {

        const calculatedPrice = formData.grossCost - ((formData.grossCost * portal.charges) / 100);
        setPrice(calculatedPrice);

      } else {
        console.log("No matching portal found");
      }

    }
  }, [portalName, data, formData.grossCost]);


  useEffect(() => {

    if (formData.proposalType === 'hourly' && formData.estimatedHours && formData.cost) {

      setFormData((prevData) => ({
        ...prevData,
        grossCost: Number(formData.estimatedHours)! * formData.cost!
      }));
    }

  }, [formData.proposalType, formData.estimatedHours, formData.cost]);

  useEffect(() => {

    if (formData.estimatedHours && formData.cost) {


      const [hoursStr, minutesStr] = formData.estimatedHours.split('.');

      const hours = Number(hoursStr);
      const minutes = Number(minutesStr) || 0;


      const totalHours = hours + (minutes / 60);

      const calculation = (totalHours * formData.cost * rates).toFixed(2)


      setFormData({ ...formData, grossCost: Number(calculation) })
    }
  }, [formData.estimatedHours, formData.cost, formData?.currency, rates])

 

  const pathElements = pathname.split('/').filter((el) => el !== '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    

    setFormData((prevData) => {

      if (name === 'proposalType' && value === 'fixed') {
        return {
          ...prevData,
          [name]: value,
          estimatedHours: "0",
          cost: undefined,
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });

    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]:value
    //   [name]: name === 'estimatedHours' || name === 'cost' ? Number(value) : value
    // }));
  };

  const handleClient = (value: { value: string; label: string }) => {



    setFormData((prevData) => ({
      ...prevData,
      "client": value.value
    }));
    setSelected2(value)


  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    if(!formData.client){
      toast({
        title: 'Kindly provide the client details to proceed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
return;
    }
    try {
      let url = id ? '/portal/update_proposal/' + id : '/portal/create_proposal';
      let creator = user._id;
      let remianingCost = price;
      const res = id ? await api.patch(url, { ...formData, remianingCost }) : await api.post(url, { ...formData, creator });
      if (res.data) {
        console.log('Client created successfully:', res.data);
        navigate("/proposals")
      }
    } catch (err) {
      console.error('Error creating client:', err);
    }
  };

  const formKeys = Object.keys(formData) as Array<keyof FormData>;
  const formatLabel = (label: string) => {
    if (label === 'grossCost') {
      return 'Gross Cost (USD)';
    }

    return label
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
  };


  const handleClick = (index: number) => {
    const newPath = `/${pathElements.slice(0, index + 1).join('/')}`;
    navigate(newPath);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];


      try {
        const formData = new FormData();
        formData.append('file', file);
        if (id) formData.append('id', id);


        const fileUploadRes = await axios.post(`${process.env.REACT_APP_API_URL}/api/portal/upload`, formData, { headers: { "Content-Type": "multipart/form-data" } });
        // setFileUrls(fileUploadRes.data.url);
        const newUrl = fileUploadRes.data.attachments;
        setFileUrls((prevUrls) => [...prevUrls, newUrl]);


      } catch (err) {
        console.error('Error uploading file:', err);
      }
    }
  };


  const handleFileDelete = (url: string) => {
    setFileUrls(fileUrls.filter(fileUrl => fileUrl !== url));
  };



  useEffect(() => {
    // Fetch exchange rates on component mount
    const fetchRates = async () => {
      try {
        const response = await axios.get(`https://v6.exchangerate-api.com/v6/98a265629f6f37c3eaf2e783/latest/${formData?.currency}`);
        setRates(response.data.conversion_rates.USD);

      } catch (error) {
        console.error('Error fetching exchange rates', error);
      }
    };
    if (formData?.currency) {
      fetchRates();
    }

  }, [apiKey, formData.currency]);





  return (
    <>
      {loadingid ? <LoadingSpinner /> : <>
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Client</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <Input
                  placeholder='Enter client name'
                  value={clientName}
                  onChange={handleChangeClientName}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button bg="#F36E21" color="white" _hover={{ color: "black", bg: "#dfd6d68a" }} mr={3} onClick={handleCreateClient}>
                Create
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box mb={10} mt={-5}>
          {pathElements.slice(0, 1).map((element, index) => (
            <span key={index} style={{ color: "black" }}>
              <Link to={`/${pathElements.slice(0, index + 1).join('/')}`} onClick={() => handleClick(index)}>
                {element}
              </Link>
              {index !== pathElements.length - 1 && ' / '}
            </span>
          ))}
          <Heading color='#F36E21'>{id ? 'Edit Proposal' : 'Create Proposal'}</Heading>

        </Box>
        <form onSubmit={handleSubmit}>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            {formKeys.map((key) => {

              if (key === 'proposalType') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Shivam
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Select ${formatLabel(key)}`}
                    >
                      {["hourly", "fixed"].map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </Shivam>
                  </FormControl>
                );
              }
              else if (key === 'currency') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>currency</FormLabel>
                    <Shivam
                      placeholder='Select currency'
                      name={key}
                      onChange={handleChange}
                      value={formData[key]}
                    >
                      {["USD", "INR", "AUD", "GBP", "EUR", "CAD", "NZD", "SGD",].map(currency => (
                        <option key={currency} value={currency}>{currency}</option>
                      ))}
                    </Shivam>
                  </FormControl>
                )
              }
              else if (key === 'client') {
                return (
                  <FormControl key={key} isRequired >

                    <Flex justify="space-between" align="center">
                      <FormLabel>{formatLabel(key)}</FormLabel>
                      <Box style={{ margin: "3px", background: "#F36E21", padding: "2px 5px 2px 5px", color: "white", borderRadius: "5px" }}

                        onClick={handleOpenModal}>Add Client</Box>
                    </Flex>
                    <Select
                      menuPlacement='top'
                      value={selected2}
                      onChange={(selectedOption) => handleClient(selectedOption as any)}
                      name="colors"
                      options={clientData?.map((el) => ({
                        value: el._id,
                        label: el.clientName,
                      })) as any}

                      placeholder="Select client"
                      closeMenuOnSelect={false}
                      size="md"
                    />
                  </FormControl>
                );
              } else if (key === 'proposalDesc') {
                return (
                  <FormControl className='proposal_dec' key={key}>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Textarea name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                      required></Textarea>
                    {/* <Input type="file"  /> */}
                    <div className='custom_upload'>
                      <label className='custom_upload_label' htmlFor='upload_file'>
                        <input id="upload_file" type='file' onChange={handleFileChange} />
                        <span className='upload_icon'><FaFileUpload /></span>
                        Upload file
                      </label>
                      {fileUrls.length > 0 && fileUrls.map((url, index) => (
                        <Flex key={index} align="center" ml={2}>
                          {/* <img src={image} alt={`Uploaded file ${index + 1}`} width={50} height={50} /> */}
                          <span>{url}</span>
                          <Button onClick={() => handleFileDelete(url)} ml={2}><FaTimes /></Button>
                        </Flex>
                      ))}
                    </div>
                  </FormControl>
                );
              } else if (key === 'status') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>Status</FormLabel>
                    <Shivam
                      placeholder='Select status'
                      name={key}
                      onChange={handleChange}
                      value={formData[key]}
                    >
                      {["hired"].map(status => (
                        <option key={status} value={status}>{status}</option>
                      ))}
                    </Shivam>
                  </FormControl>
                )
              } else if (key === 'projectDeadline') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{`${formatLabel(key)}`}</FormLabel>
                    <Input
                      name={key}
                      type='date'
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </FormControl>
                )
              }
              else if (key === 'estimatedHours' && formData.proposalType === 'hourly') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Input
                      name={key}
                      type='number'

                      value={formData[key] || ''}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                    />
                  </FormControl>
                )
              }
              else if (key === 'cost' && formData.proposalType === 'hourly') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Input
                      name={key}
                      type='number'
                      value={formData[key] || ''}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                    />
                  </FormControl>
                )
              }
              else if (key === 'grossCost') {
                return (
                  <FormControl key={key} isRequired >
                    <Flex align="center">
                      <FormLabel flex="1">{formatLabel(key)}</FormLabel>
                      {price !== null && (
                        <Box>Remaining Cost: ${price.toFixed(2)}</Box>
                      )}
                    </Flex>
                    <Input
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                      readOnly={formData.proposalType === 'hourly'}
                    />
                  </FormControl>
                )
              }
              return null;
            })}
          </Grid>
          <Box mt={10}>
            <Stack direction='row' justify={'flex-end'} spacing={10}>
              <Button bg="#F36E21" color="white" _hover={{ color: "black", bg: "#dfd6d68a" }} type="submit">
                {t('Submit')}
              </Button>
              <Button onClick={() => navigate("/proposals")}>{t('Cancel')}</Button>
            </Stack>
          </Box>
        </form></>}
    </>
  );
};

export default HiredProposal;
