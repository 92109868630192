import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/UI/loadingSpinner/LoadingSpinner";
import CreateModal from "../components/modal/CreateModal";
import CustomTable from "../components/tables/customTable/CustomTable";
import useDataFetch from "../hook/useDataFetch";
import { IClientsTable } from "../interfaces/Itable";
import { Input } from "@chakra-ui/react";
import LoginContext from "../store/loginContext";

function Clients() {
  const { t } = useTranslation();
  const portalUrl = `${process.env.REACT_APP_API_URL}/api/portal/clients`;
  const { data, error, loading ,refetch} = useDataFetch<IClientsTable[]>(portalUrl);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<IClientsTable[]>([]);
  const { user } = useContext(LoginContext);
  const [selectedClient, setSelectedClient] = useState<string>("");

  useEffect(() => {
    if (selectedClient) refetch()
    }, [selectedClient])



  useEffect(() => {
    if (data && user) {

  const filtered = data.filter((item) =>
          item?.clientName?.toLowerCase().includes(searchTerm?.toLowerCase()))
        .sort((a, b) => a?.clientName.localeCompare(b?.clientName)); 
      setFilteredData(filtered);
    }
  }, [data, searchTerm,user]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <p>Error loading clients</p>
      </div>
    );
  }


  return (
    <section>
      <div className="portal_creation">
        <h2 className="title">{t("clients")}</h2>
        <CreateModal button={"Create Client"} header={"Create client"} num={10}  setSelectedClient={setSelectedClient}/>
      </div>
      <Input
      mb={4}
      w={"auto"}
          type="text"
          placeholder="Search by client name "
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

     { filteredData.length > 0 ?     
     <CustomTable key={Date.now()}
        headData={[
          "ID",
          "clientName",
          "email",
          "phone",
          "country",
          "skype",
          "invited",
          "actions",
        ]}
        bodyData={filteredData}
        limit={10}
        setSelected={setSelectedClient}
      />:(
        <p>No matching clients found</p>
      )}
    </section>
  );
}

export default Clients;
