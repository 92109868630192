import React, {useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import {Itable as Props, complex } from "../../../interfaces/Itable";
import Card from "../../UI/card/Card";
import Badge from "../../UI/badge/Badge";
import Modal from "../../UI/modal/Modal";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import classes from "./CustomTable.module.scss";
import EditModal from "../../modal/EditModal";
import api from "../../../utils/api";
import { Select, useToast } from "@chakra-ui/react";




const CustomTable: React.FC<Props> = (props) => {
 

  const [showModal, setShowModal] = useState(false);
  const [tablename, setTableName] = useState('');
  const [id, setId] = useState<number | string | null>(null);
  const navigate = useNavigate();
  const toast=useToast();

function showModalHandler(id:number | string ,name:string) {
    setId(id);
    setTableName(name);
    setShowModal((prev) => !prev);
  }

const deleteModal=async(command:string)=>{
  if(command==="delete"){
  try {
    const url =tablename === 'users'? '/users/'+id:tablename === 'proposal'? '/portal/remove_proposal/'+id: tablename === 'client'? '/portal/remove_client/'+id: tablename === 'portal' ? '/portal/remove/'+id : tablename === 'portalProfile' ? '/portal/remove_profile/'+id : tablename === "upsell" ? '/portal//remove_upsell/'+id : '/portal/remove_category/'+id ; 
    const res = await api.delete(url);
    if(res.data){
    
      if (props.setSelected) {
        props.setSelected(Date.now().toString());
    }

    toast({
      title: 'Deletion completed successfully.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
     
    }
  } catch (error) {
    console.error('Error:', error);
  }}else{
    setShowModal((prev) => !prev);

  }

}
const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>, id: number | string) => {


  const selectedStatus = e.target.value;

  try {
    if(selectedStatus === "hired"){
      navigate(`/proposals/hired/${id}`);
    }
    else{
    const res = await api.patch(`/portal/update_proposal/${id}`, { status: selectedStatus });
    if (res.data) {
      console.log('Client updated successfully:', res.data);
      // if(selectedStatus === "hired"){
      //   navigate(`/proposals/hired/${id}`);
      // }else{
        window.location.reload();
      // }
    }
  }
  } catch (err) {
    console.error('Error updating client:', err);
  }
};

// const handleChange=async(e: React.ChangeEvent<HTMLSelectElement>,id:number | string) => {
//   const selectedStatus = e.target.value;
//   try {
//     const res = await api.patch('/portal/update_proposal/'+id,{status:selectedStatus});
//     if (res.data) {
//       console.log('Client updated successfully:', res.data);
//       window.location.reload();
//     }
//   } catch (err) {
//     console.error('Error creating client:', err);
//   }
// }


  function tableBody(item: complex, index: number) {
   
    const statusColors = {
      new: 'black', 
      discussion: '#F36E21',
      hired: 'green',
      completed: 'blue',
      rejected: 'red',
      pause: 'gray',
  };

  //   function formatDateTime(date:any) {
  //     const options = {
  //         day: '2-digit',
  //         month: '2-digit',
  //         year: 'numeric',
  //         hour: '2-digit',
  //         minute: '2-digit',
  //         hour12: true
  //     };
  //     return date.toLocaleString('en-GB', options).replace(',', '');
  // }
  function formatDateTime(date: any) {
    const optionsDate = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    const formattedDate = date.toLocaleDateString('en-GB', optionsDate).replace(',', '');
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

    return { date: formattedDate, time: formattedTime };
}

const handleRowClick = (userId: string) => {
  navigate(`/users/${userId}`);
};


    /* type guard (in typescript) */

    if ("companyName" in item && props.userdata) {

      return (
        <tr key={item?._id}>
          <td>{index + 1}</td>
        <td>{item?.proposalLink ? item.proposalLink.slice(0, 20) + (item.proposalLink.length > 20 ? '...' : '') : ''}</td>
          <td>{item?.profile?.profileName}</td>
          <td>{item?.totalConnects ?? 0}</td>
          <td>{item?.portal?.portalName}</td>
        </tr>
      );
    }
    else if ("profileLink" in item && props.userdata ) {   
        return (
          <tr key={item?._id}>
            <td>{index + 1}</td>
            <td>{item?.profileName}</td>
             <td>{typeof item?.portal === "string" ? props.portalIdToName[item?.portal] : ''}</td>
            <td>{item?.totalProposalCount}</td>
            <td>{item?.hiredProposalCount}</td>
          </tr>
        )
     
     
    }
   else if ("clientName" in item) {
      //for implementing top customers
      return (
        <tr key={item._id}>
        <td>{index+1}</td>
        <td className={classes.product_name}>
          <img
            className={classes.product_img}
            src={item.clientImg || 'https://avatar.iran.liara.run/public/'+index}
            alt="user avatar"
          />
          {item?.clientName}
        </td>
        <td>{item?.clientEmail}</td>
        <td>{item?.clientPhone}</td>
        <td>{item?.clientCountry}</td>
        <td>{item?.clientSkype || "N/A"}</td>
        <td>{item?.invited?"yes":"no"}</td>
        <td className={classes.actions}>
          <Icon icon="charm:menu-kebab" />
          <div className={classes.actions__box}>
            <div
              className={classes.actions__delete}
              onClick={()=>showModalHandler(item?._id,"client")}

            >
              <Icon icon="fluent:delete-24-regular" width="24" />
            </div>
            <div className={classes.actions__edit}>
            <EditModal  header="Edit Client" num={10} item={item} setSelected={props.setSelected}/>
            </div>
          </div>
        </td>
      </tr>
      );
    }
    else if ("jobName" in item) {
      //for implementing top customers
      return (
        <tr key={item._id}>
          <td>{index+1}</td>
          <td>{item?.jobName}</td>
          <td>{item?.desc}</td>
          <td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?._id,"jobCategory")}

              >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
              <div className={classes.actions__edit}>
                 <EditModal  header="Edit Category" num={2} item={item} setSelected={props.setSelected}/>
              </div>
            </div>   
          </td>
        </tr> 
      );
    }
    else if ("portalName" in item) {
      //for implementing top customers
      return (
        <tr key={item._id}>
          <td>{index+1}</td>
          <td>{item?.portalName}</td>
          <td>{item?.link}</td>
          <td>{item?.charges}</td>
          <td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?._id,"portal")}
              >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
              <div className={classes.actions__edit}>
                 <EditModal header="Edit Portal" num={0} item={item} setSelected={props.setSelected}/>
              </div>
            </div>
          </td>
        </tr>
      );
    }
    else if ("profileName" in item) {
      //for implementing top customers
      return (
        <tr key={item?._id}>
          <td>{index+1}</td>
          <td>{item?.profileName}</td>
          <td>{typeof item?.portal ==="object" ?item?.portal?.portalName :""}</td>
          {/* <td>{item?.jobCategory?.jobName}</td> */}
          <td>{item?.profileLink}</td>
          <td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?._id,"portalProfile")}
              >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
              <div className={classes.actions__edit}>
                 <EditModal header="Edit profile" num={1} item={item} setSelected={props.setSelected}/>
                </div>
            </div>
          </td>
        </tr>
      );
    }
    else if ("companyName" in item) {
      
      //for implementing top customers
      return (
        <tr key={item?._id}>
          <td>{index+1}</td>
          <td>{item?.creator?.name}</td>
          <td>{item?.proposalLink ? item?.proposalLink.slice(0, 20) + (item.proposalLink.length > 20 ? '...' : '') : ''}</td>
          <td>{item?.profile?.profileName}</td>
          {/* <td>{item.cost}</td> */}
          <td>{item?.totalConnects ?? 0}</td>
          {/* <td>{item?.client?.clientName}</td> */}
          <td><Select onChange={(e)=>handleChange(e,item._id)} value={item?.status}>{["new","discussion","hired","completed","rejected","pause",].map((el)=>(
            <option key={el} value={el}  style={{ color: statusColors[el as keyof typeof statusColors] || 'black' }}>{el}</option>
            ))}</Select>
            </td>
          {/* <td>{new Date(item?.createdAt).toLocaleDateString('en-GB')}</td> */}
          {/* <td>{formatDateTime(new Date(item?.createdAt))}</td> */}
          <td>
    <span>{formatDateTime(new Date(item?.createdAt)).date}</span>{' '}
    <span style={{ color: '#F36E21', fontSize: 'smaller' }}>{formatDateTime(new Date(item?.createdAt)).time}</span>
</td>

          <td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?._id,"proposal")}
              >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
              <div className={classes.actions__edit}>
              <Link to={`/proposals/edit/${item?._id}`}>
                  <Icon icon="fluent:edit-16-regular" width="24" />
                </Link>
                </div>
            </div>
          </td>
        </tr>
      );
       } else if ("username" in item) {
      //for implementing top customers
      return (
        <tr key={index}>
          <td>{item?.username}</td>
          <td>{item?.order}</td>
          <td>{item?.price}</td>
        </tr>
      );
    } 
    else if ("targetAmt" in item) {
      return (
        <tr key={index}>
            <td>{index+1}</td>
          
          <td>{item?.targetAmt}</td>
          <td>
    <span>{formatDateTime(new Date(item?.targetDate)).date}</span>
    {/* <span style={{ color: '#F36E21', fontSize: 'smaller' }}>{formatDateTime(new Date(item?.targetDate)).time}</span> */}
</td>
        </tr>
      );
    }
    
    else if ("orderId" in item) {
      //for implementing latest transactions
      return (
        <tr key={index}>
          <td>{item?.orderId}</td>
          <td>{item?.customer}</td>
          <td>{item?.totalPrice}</td>
          <td>{item?.date}</td>
          <td>
            <Badge content={item?.status} />
          </td>
        </tr>
      );
    } 
    else if ("email" in item && "ID" in item) {
      //for implementing customers table
      return (
        <tr key={index}>
          <td>{item?.ID}</td>
          <td className={classes.userName}>
            <img
              className={classes.avatar}
              src={item?.avatar}
              alt="user avatar"
            />
            {item?.userName}
          </td>
          <td className="ltr">{item?.portal}</td>
          <td className="ltr">{item?.profile}</td>
          <td>{item?.connectsUsed}</td>
          <td>{item?.cost}</td>
          <td>{item?.client}</td>
          <td>{item?.status}</td>
          <td>{item?.createdOn}</td>
          <td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?.ID,"portal")}

              >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
              <div className={classes.actions__edit}>
                <Link to={`/customers/${item.ID}`}>
                  <Icon icon="fluent:edit-16-regular" width="24" />
                </Link>
              </div>
            </div>
          </td>
        </tr>
      );
    }
     else if ("target" in item && "profilesAllocated" in item) {
      const getLatestTargetAmt = (targets: { targetDate: Date; targetAmt: number }[]): number | null => {
        if (!targets || targets.length === 0) return null;
        const latestTarget = targets.reduce((latest, current) => {
          return new Date(current.targetDate) > new Date(latest.targetDate) ? current : latest;
        });
        return latestTarget.targetAmt;
      };
      
      
      return (
        <tr key={(item._id)} >

          <td>{index+1}</td>
          <td className={classes.product_name}    onClick={() => handleRowClick(String(item._id))}>
            <img
              className={classes.product_img}
              src={item?.avatar}
              alt="user avatar"
            />
            {item?.name}
          </td>
          {/* <td>{item?.email}</td> */}
          <td>
          {getLatestTargetAmt(item?.target)}
    </td>
          <td>{t(item?.role)}</td>
  {/* <td >
  {item?.profilesAllocated && item.profilesAllocated.map((el,i) => (
    // eslint-disable-next-line no-useless-concat
    typeof el === 'object' && el?.profileName ? `${el.profileName}${i!==item?.profilesAllocated.length-1 ? ','+' ':'' }`: null
))} </td> */}

          <td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?._id,"users")} >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
           
              <div className={classes.actions__edit}>
                 <EditModal header="Edit profile" num={11} item={item} setSelected={props.setSelected}/>
                </div>
                <div
                className={classes.actions__delete}
                onClick={() => handleRowClick(String(item._id))}
                >
                   <Icon icon="mdi:eye" width="24" />
              </div>
            </div>
          </td>
        </tr>
      );
    }
     else if ("category" in item) {
      //for implementing products table
      return (
        <tr key={index}>
          <td>{item?.ID}</td>
          <td className={classes.product_name}>
            <img
              className={classes.product_img}
              src={item?.pic}
              alt="user avatar"
            />
            {item?.product}
          </td>
          <td>{item?.inventory}</td>
          <td>{item?.price}</td>
          <td>{item?.category}</td>
          <td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?.ID,"portal")}

              >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
              <div className={classes.actions__edit}>
                <Link to={`/products/${item.ID}`}>
                  <Icon icon="fluent:edit-16-regular" width="24" />
                </Link>
              </div>
            </div>
          </td>
        </tr>
      );
    }

    else if("upsellTitle" in item){
      return(
        <tr key={index}>
          <td>{index + 1}</td>
       
          <td>{item?.userName}</td>
               
          <td>{item?.profile?.profileName}</td>
          <td>{item?.upsellLink ? item?.upsellLink?.slice(0, 20) + (item?.upsellLink?.length > 20 ? '...' : '') : ''}</td>
          <td>{item?.portal?.portalName}</td>
          <td>
    <span>{formatDateTime(new Date(item?.createdAt)).date}</span>{' '}
    <span style={{ color: '#F36E21', fontSize: 'smaller' }}>{formatDateTime(new Date(item?.createdAt)).time}</span>
</td>
<td className={classes.actions}>
            <Icon icon="charm:menu-kebab" />
            <div className={classes.actions__box}>
              <div
                className={classes.actions__delete}
                onClick={()=>showModalHandler(item?._id,"upsell")}

              >
                <Icon icon="fluent:delete-24-regular" width="24" />
              </div>
              <div className={classes.actions__edit}>
                <Link to= {`/upsell/edit/${item._id}`}
                >
                  <Icon icon="fluent:edit-16-regular" width="24" />
                </Link>
              </div>
            </div>
          </td>
          </tr>
      )
    }

     
    else if ("month" in item) {

      return (
        <tr key={index}>
          <td>{index+1}</td>
          <td>{item?.month}</td>
          <td>${item?.target}</td>
          <td>${item?.achieved}</td>
     
        </tr>
      );
    }


  }
  

  const initDataShow = () => {
    return props.limit && props.bodyData
      ? props.bodyData.slice(0, Number(props.limit))
      : props.bodyData;
  };

  const [dataShow, setDataShow] = useState(initDataShow);
  // const [selectedCategory, setSelectedCategory] = useState(
  //   props.selectedCategory
  // );

  // if (props.selectedCategory) {
  //   if (selectedCategory !== props.selectedCategory)
  //     setDataShow(props.bodyData);
  // }
  // setSelectedCategory(props.selectedCategory);

  // let pages = 1;

  // let range: number[] = [];

  // if (props.limit !== undefined) {
  //   let page = Math.floor(props.bodyData.length / Number(props.limit));
  //   pages = props.bodyData.length % Number(props.limit) === 0 ? page : page + 1;
  //   range = [...Array(pages).keys()];
  // }

  const [currPage, setCurrPage] = useState(0);

  const selectPage = (page: number) => {
    const start = Number(props.limit) * page;
    const end = start + Number(props.limit);

    setDataShow(props.bodyData?.slice(start, end));

    setCurrPage(page);
  };

  const { t } = useTranslation();


  const generatePaginationRange = (totalPages: number, currentPage: number) => {
    const range = [];
    const maxPagesToShow = 5;
  
    if (totalPages <= maxPagesToShow) {
      for (let i = 0; i < totalPages; i++) {
        range.push(i);
      }
    } else {
      range.push(0); // First page
      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(totalPages - 2, currentPage + 1);
  
      if (startPage > 1) {
        range.push('...');
      }
  
      for (let i = startPage; i <= endPage; i++) {
        range.push(i);
      }
  
      if (endPage < totalPages - 2) {
        range.push('...');
      }
  
      range.push(totalPages - 1); // Last page
    }
  
    return range;
  };
  
  const pages = props.limit !== undefined 
    ? Math.ceil(props.bodyData.length / Number(props.limit))
    : 1;
  
  const paginationRange = generatePaginationRange(pages, currPage);


  return (
    <>
      {/* modal for delete customer and product case*/}
      {showModal ? (
        <Modal
          title={t("deleteCustomer")}
          message={`${t("modalMessage")}`}
          onConfirm={deleteModal}
        />
      ) : null}

      <div className={classes.container}>
        <Card>
          <div className={classes.wrapper}>
            <div className={classes.table__wrapper}>
              <table
                className={props.limit ? classes.largeTable : classes.table}
              >
                {props.headData ? (
                  <thead>
                    <tr>
                      {props.headData.map((item, index) => (
                        <th key={index}>{t(item)}</th>
                      ))}
                    </tr>
                  </thead>
                ) : null}
                <tbody>
                  {dataShow.map((item, index) => tableBody(item, index))}
                </tbody>
              </table>
            </div>

            {/* {pages > 1 ? (
              <div className={classes.table__pagination}>
                {range.map((item, index) => (
                  <div
                    key={index}
                    className={`${classes.table__pagination_item} ${
                      currPage === index ? classes.active : ""
                    }`}
                    onClick={() => selectPage(index)}
                  >
                    {item + 1}
                  </div>
                ))} */}
                {pages > 1 ? (
            <div className={classes.table__pagination}>
              {paginationRange.map((item, index) => (
                <div
                  key={index}
                  className={`${classes.table__pagination_item} ${
                    currPage === item ? classes.active : ""
                  }`}
                  onClick={() => typeof item === 'number' && selectPage(item)}
                >
                  {typeof item === 'number' ? item + 1 : item}
                </div>
              ))}
              </div>
            ) : null}
          </div>
        </Card>
      </div>
    </>
  );
};

export default CustomTable;
